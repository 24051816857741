import {axios} from "../utils";

import { ResetPasswordType, SignInType } from "../types/auth";

export function signIn(credentials: SignInType) {
  return new Promise((resolve, reject) => {
    axios({
      url: "auth/local",
      data: JSON.stringify(credentials)
    }).then((response) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export function signOut() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

export function fetchUser(accessToken) {
  return new Promise((resolve, reject) => {
    axios({
      url: '/users/me',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      method: 'GET'
    }).then((response) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

// export function resetPassword(credentials: ResetPasswordType) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post("/api/auth/reset-password", credentials)
//       .then((response) => {
//         if (response.status === 200) {
//           resolve(response.data);
//         }
//         reject(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }