import * as types from '../constants';

export const authSignInSuccess = (data: any) => {
  return ({
    type: types.AUTH_SIGN_IN_SUCCESS,
    ...data
  });
};

export const authSignOut = () => {
  return ({
    type: types.AUTH_SIGN_OUT
  });
}