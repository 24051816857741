import React from 'react';
import {default as _axios} from 'axios';
import {
  signOut as authSignOutSuccess,
} from "../services/authService";
import { authSignOut } from "../actions/authActions";
import {AuthContext} from '../contexts/AuthContext';

const useUtils = () => {
  const {dispatch, state: authState} = React.useContext(AuthContext);

  const handleSignOut = async () => {
    authSignOutSuccess();
    dispatch(authSignOut());
  };

  const axios = _axios.create({
    // Do something before request is sent
    baseURL: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json'
  });
  
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function(err) {
    if(err.response && err.response.status && err.response.status === 401) {
      // handleSignOut();
    }
  
    return Promise.reject(err);
  });

  
  const dataURLtoFile = (dataurl:string, filename:string) => {
    const arr = dataurl.split(',');
    const mimeArr = arr[0].match(/:(.*?);/);

    if(mimeArr && mimeArr[1]){
      const mime = mimeArr[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
    
      // console.log('aca arr', arr);
      // console.log('aca mime', mime);
      // console.log('aca bstr', bstr);
      // console.log('aca u8arr', u8arr);
      
      while (n) {
        u8arr[n-1] = bstr.charCodeAt(n-1)
        n -= 1 // to make eslint happy
      }
    
      return new File([u8arr], filename, { type: mime })
    } else{
      throw "Cannot get mime of url";
    }
  }

const formatNumber = (amount=0, decimalCount = 0, decimal = ".", thousands = ",") => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 0 : decimalCount;
  const numericSign = amount < 0 ? "-" : "";
  const parts = amount.toString().split(decimal);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands);

  if(parts[1] && decimalCount > 0) {
    if(parts[1].length == 1) {
      parts[1] = parts[1] + (new Array(decimalCount-1)).fill("0").toString();
    } else if(parts[1].length > 1) {
      parts[1] = parts[1].substring(0, decimalCount);
    } else {
      parts[1] = (new Array(decimalCount)).fill("0").toString();
    }

    return numericSign + parts.join(decimal);
  } else{
    return numericSign + parts[0];
  }
}

const isValidDate = (d:any) => {
  return d instanceof Date;
}

const getUrlParameters = (search) => {
  var sPageURL = search.substring(1);
  var sURLVariables = sPageURL.split('&');
  var params = [];

  for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      
      if(sParameterName[0]) {
        params[sParameterName[0]] = decodeURI(sParameterName[1]);
      }
  }

  return params;
};

  return {
    axios,
    handleSignOut,
    dataURLtoFile,
    formatNumber,
    isValidDate,
    getUrlParameters
  }
}

export default useUtils;