import { useState, useEffect, useContext } from 'react';
import { Menu, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProjectContext } from "../contexts/ProjectContext";
import { fetchProjects } from '../services/projectService';
import { AuthContext } from "../contexts/AuthContext";
import ApartmentIcon from '@material-ui/icons/Apartment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ProjectChooser = () => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { state: authState } = useContext(AuthContext);
  const { state: projectState, dispatch} = useContext(ProjectContext);

  useEffect(()=> {
    if(authState.user) {
      fetchProjects(authState.token).then((data:any)=>{
        setOptions(data);
        let selected = data.find(item => item.id == projectState.projectID);
        if(selected){
          setSelectedProject(selected)
        }
      })
    }
  }, [authState])
 
  const onChangeProject = (project) => (event:any) => {
    setAnchorEl(null);
    setSelectedProject(project);

    dispatch({
      type: 'ID_CHANGED',
      payload: project
    })
  }

  if(!selectedProject) return null

  console.log('aca', anchorEl);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={classes.button}
      >
        {selectedProject.logo? 
          <img src={selectedProject.logo.url} className={classes.logo}/>
        :<ApartmentIcon />}
        {selectedProject.name}
      </Button>
      <Menu 
        open={open}
        anchorEl={anchorEl}
      >
        {options.length > 0 && (
          options.map((option, index) => (
            <MenuItem key={index} classes={{ root: classes.rootPaper }} onClick={onChangeProject(option)}>
              {option.logo? 
                <img src={option.logo.url} className={classes.logo}/>
              :<ApartmentIcon />}
              {option['name']}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
}

export default ProjectChooser;

const useStyles = makeStyles((theme) => ({
  button: {
    padding:'6px 10px',
    background: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '8px',
    color: 'inherit'
  },
  rootPaper: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    padding:'6px 10px',
    fontSize: "1rem",
    color: 'rgba(0, 0, 0, 0.54)',
    height: '3rem',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },
  },

  logo: {
    maxHeight: '1rem',
    maxWidth: '2.5rem',
    marginRight: "5px"
  }
}));