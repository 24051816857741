import * as types from "../constants";
import {AuthType } from '../types/auth';

const initialState = {
  user: localStorage.user? JSON.parse(localStorage.user): null,
  token: localStorage.token? localStorage.token: ''
}

const reducer = (
  state: AuthType, 
  action: any//AuthType & { type: string }
  ):AuthType => {
  switch (action.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        user: {
          id: action.user.id,
          email: action.user.email,
          name: action.user.name,
          rules: action.user.rules,
        },
        token: action.token
      };

    case types.AUTH_SIGN_OUT:
      return {
        user: null,
        token: ''
      };
    
    case types.USER_UPDATE_NAME:
      return {
        user: {
          id: state.user.id,
          email: state.user.email,
          name: action.payload.name,
          rules: state.user.rules,
        },
        token: state.token
      };
    default:
      return state;
  }
}

export {
  initialState,
  reducer
}