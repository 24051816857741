import React from "react";
import { Helmet } from "react-helmet";
// import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { THEMES } from "./constants";
import { SnackbarProvider } from "notistack";
import Fade from "@material-ui/core/Fade";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { ProjectContextProvider } from './contexts/ProjectContext';

const jss = create({
  ...jssPreset(),
  insertionPoint: "jss-insertion-point",
});

const theme = THEMES.DEFAULT;

function App() {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="Bohrim App"
        defaultTitle="Bohrim App - Validation and KPI's dashboard"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <ThemeProvider theme={createTheme(theme)}>
              <SnackbarProvider maxSnack={3}>
                <AuthContextProvider>
                  <ProjectContextProvider>
                    <AnalyticsContextProvider>
                      <Routes />
                    </AnalyticsContextProvider>
                  </ProjectContextProvider>
                </AuthContextProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
