import {axios} from "../utils";

export function fetchProjects(accessToken: string) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/projects",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      method: 'GET'
    }).then((response) => {
      console.log("response: ", response);
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    }).catch((error) => {
      console.log("dashboard service daily notes error: ", error);
      reject(error);
    });
  });
}