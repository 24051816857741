import { ReactNode, createContext, useReducer } from "react";
import { ProjectType, ProjectContextType } from '../types/project'

const initialState = {
  projectID: localStorage.projectID?parseInt(localStorage.getItem('projectID')):1,
  projectName: localStorage.projectName?localStorage.getItem('projectName'):'P6 - Refinería Dos Bocas',
  lon: localStorage.lon?localStorage.getItem('lon'):'-93.195136',
  lat: localStorage.lat?localStorage.getItem('lat'):'18.4220809',
}

const ProjectContext = createContext<ProjectContextType>({
  state: initialState,
  dispatch: null
});

const reducer = (state:ProjectType, action:any) => {
  switch (action.type) {
    case "ID_CHANGED":
      localStorage.setItem('projectID', action.payload.id); 
      localStorage.setItem('projectName', action.payload.name); 
      localStorage.setItem('lon', action.payload.lon); 
      localStorage.setItem('lat', action.payload.lat); 

      return {
        projectID: parseInt(action.payload.id),
        projectName: action.payload.name,
        lon: action.payload.lon,
        lat: action.payload.lat
      };
    
    default:
        return state;
  }
}

const ProjectContextProvider = (props: {children: ReactNode}) => {

  const {children} = props;

  const value = useProject();

  return (<ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>);
}

const useProject = () : ProjectContextType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch
  }
}


export { 
  ProjectContext, 
  ProjectContextProvider,
};