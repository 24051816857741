import React, { ReactNode } from "react";
import {AuthContextType} from '../types/auth';
import { reducer, initialState } from '../reducers/authReducer';

const AuthContext = React.createContext<AuthContextType>({
  state: {user: null, token: ''},
  dispatch: null
});

const AuthContextProvider = (props: {children: ReactNode}) => {
  const auth = useAuth();
  const {children} = props;

  return (<AuthContext.Provider value={auth}>{children}</AuthContext.Provider>);
} 

const useAuth = () : AuthContextType => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if(state.user) {
      localStorage.setItem('user', JSON.stringify(state.user));
    } 
  
    if(state.token.length > 0) {
      localStorage.setItem('token', state.token);
    }
  }, [state]);

  return {
    state,
    dispatch
  }
}


const AuthContextConsumer = AuthContext.Consumer;

export { 
  AuthContext, 
  AuthContextProvider, 
  AuthContextConsumer,
};