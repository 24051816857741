// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";


//Analytics
export const SET_GLOBAL_KPIS = "SET_GLOBAL_KPIS";
export const SET_DATE_RANGE = "SET_DATE_RANGE";

//User Updates
export const USER_UPDATE_NAME = "USER_UPDATE_NAME";