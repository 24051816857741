import * as types from "../constants";
import {AnalyticsType } from '../types/analytics';
import moment from 'moment';

const initialState = {
  globalScore: 0,
  categories: [],
  dateRange: [
    moment().day(1), moment().day(6).add(1, 'd')
  ]
}

const reducer = (
  state: AnalyticsType, 
  action: any//AnalyticsType & { type: string }
  ):AnalyticsType => {
  switch (action.type) {
    case types.SET_GLOBAL_KPIS:
      return {
        ...state,
        globalScore: action.global_kpi,
        categories: action.categories
      };

    case types.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };

    default:
      return state;
  }
}

export {
  initialState,
  reducer
}