import React, { ReactNode } from "react";
import {AnalyticsContextType} from '../types/analytics';
import { reducer, initialState } from '../reducers/analyticsReducer';

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  state: initialState,
  dispatch: null
});

const AnalyticsContextProvider = (props: {children: ReactNode}) => {
  const analytics = useAnalytics();
  const {children} = props;

  return (<AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>);
} 

const useAnalytics = () : AnalyticsContextType => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return {
    state,
    dispatch
  }
}

const AnalyticsContextConsumer = AnalyticsContext.Consumer;

export { 
  AnalyticsContext, 
  AnalyticsContextProvider, 
  AnalyticsContextConsumer,
};