const overrides = {
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },

  MuiFormControl: {
    root: {
      width: '100%'
    },
    marginNormal: {
      marginTop: '5px',
      marginBottom: '15px'
    }
  },

  MuiInputLabel: {
    root: {
      '&$outlined$shrink': {
        transform: 'translate(14px, -5px) scale(0.75)'
      }
    },

    outlined: {
      transform: 'translate(10px, 14px) scale(1)'
    }
  },

  MuiFormControlLabel: {
    root: {
      marginLeft: '0px',
      marginRight: '0px'
    }
  },

  MuiCheckbox: {
    root: {
      paddingLeft: '0px',
      paddingTop: '0px'
    },
  },

  MuiOutlinedInput: {
    root : {
      height: '40px',
      background: '#fff',

      '& $notchedOutline': {
        borderRadius: '2px'
      },

      '&$multiline': {
        height: 'auto'
      }
    },

    input: {
      padding: '12px'
    }
  },
};

export default overrides;
