import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Button,
  Box
} from "@material-ui/core";
import {ArrowBackIos as ArrowBackIosIcon, Today as TodayIcon, Home, Person } from "@material-ui/icons";
import ProjectChooser from './ProjectChooser';

// import NotificationsDropdown from "./NotificationsDropdown";
// import MessagesDropdown from "./MessagesDropdown";
// import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  position: relative;
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: #fff;
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    font-size: 1rem;
    height: 1.8rem;
  }
`;

const CalendarButton = styled(TodayIcon)`
  color: #fff;
`
const PersonIcon = styled(Person)`
  color: #fff;
`

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" justify="flex-start" >
                {history.length > 0 && history.location.pathname != "/dashboard"?
                  <IconButton
                    color="inherit"
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                :null}
                {history.location.pathname != "/dashboard"?
                  <Button onClick={() => {history.push('/dashboard')}} style={{'color': 'white'}}>
                      <Home />
                  </Button>
                :null}
                <ProjectChooser />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center" justify="flex-end">
                <Button onClick={() => {history.push('/dashboard/perfil')}}>
                  <PersonIcon/>
                </Button>
                <Button onClick={() => {history.push('/dashboard/calendario')}}>
                  <CalendarButton/>
                </Button>
                <UserDropdown />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
};

export default withTheme(AppBarComponent);
