import {default as _axios} from 'axios';

export const axios = _axios.create({
  // Do something before request is sent
  baseURL: process.env.REACT_APP_API_URL,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json'
});

export const dataURLtoFile = (dataurl:string, filename:string) => {
  const arr = dataurl.split(',');
  const mimeArr = arr[0].match(/:(.*?);/);

  if(mimeArr && mimeArr[1]){
    const mime = mimeArr[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    // console.log('aca arr', arr);
    // console.log('aca mime', mime);
    // console.log('aca bstr', bstr);
    // console.log('aca u8arr', u8arr);
    
    while (n) {
      u8arr[n-1] = bstr.charCodeAt(n-1)
      n -= 1 // to make eslint happy
    }
  
    return new File([u8arr], filename, { type: mime })
  } else{
    throw "Cannot get mime of url";
  }
}

export function formatNumber(amount=0, decimalCount = 0, decimal = ".", thousands = ",") {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 0 : decimalCount;
  const negativeSign = amount < 0 ? "-" : "";
  const parts = amount.toString().split(decimal);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands);

  if(parts[1] && decimalCount > 0) {
    if(parts[1].length == 1) {
      parts[1] = parts[1] + (new Array(decimalCount-1)).fill("0").toString();
    } else if(parts[1].length > 1) {
      parts[1] = parts[1].substring(0, decimalCount);
    } else {
      parts[1] = (new Array(decimalCount)).fill("0").toString();
    }

    return negativeSign + parts.join(decimal);
  } else{
    return negativeSign + parts[0];
  }
}

export const getUrlParameters = (search) => {
  var sPageURL = search.substring(1);
  var sURLVariables = sPageURL.split('&');
  var params = [];

  for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      
      if(sParameterName[0]) {
        params[sParameterName[0]] = decodeURI(sParameterName[1]);
      }
  }

  return params;
};

export const allZeros = (array) => {
  for(var i = 0; i < array.length; ++i) {
    if(array[i] !== 0) {
      return false;
    }
  }
  return true;
}

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}
