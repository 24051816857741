import React from "react";

import async from "../components/Async";

import {
  Calendar as CalendarIcon,
  Monitor,
  Sliders,
  Users,
} from "react-feather";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
// const Alerts = async(() => import("../pages/components/Alerts"));
// const Avatars = async(() => import("../pages/components/Avatars"));
// const Badges = async(() => import("../pages/components/Badges"));
// const Buttons = async(() => import("../pages/components/Buttons"));
// const Cards = async(() => import("../pages/components/Cards"));
// const Chips = async(() => import("../pages/components/Chips"));
// const Dialogs = async(() => import("../pages/components/Dialogs"));
// const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
// const Lists = async(() => import("../pages/components/Lists"));
// const Menus = async(() => import("../pages/components/Menus"));
// const Pagination = async(() => import("../pages/components/Pagination"));
// const Progress = async(() => import("../pages/components/Progress"));
// const Snackbars = async(() => import("../pages/components/Snackbars"));
// const Tooltips = async(() => import("../pages/components/Tooltips"));

// // Dashboards components
const Dashboard = async(() => import("../pages/dashboards"));
// const Analytics = async(() => import("../pages/dashboards/Analytics"));
// const SaaS = async(() => import("../pages/dashboards/SaaS"));

//audit routes
const AuditMenu = async(() => import("../pages/dashboards/Audit"));
const AuditForm = async(() => import("../pages/dashboards/Audit/form"));
const AuditList = async(() => import("../pages/dashboards/Audit/list"));
const AuditDetail = async(() => import("../pages/dashboards/Audit/detail"));
const AuditMultimedia = async(() => import("../pages/dashboards/Audit/Multimedia/index"));
const AuditPdfDetail = async(() => import("../pages/dashboards/Audit/DetailPdf"));

//audit analytics
const Analytics = async(() => import("../pages/dashboards/Audit/analytics"));
const Category = async(() => import("../pages/dashboards/Audit/analytics/Category"));
const AuditsPerZone = async(() => import("../pages/dashboards/Audit/analytics/AuditsPerZone"));
const AuditsPerContractor = async(() => import("../pages/dashboards/Audit/analytics/AuditsPerContractor"));
const ContractorsAdvanced = async(() => import("../pages/dashboards/Audit/analytics/ContractorsAdvanced"));
const ContinouosImprovement = async(() => import("../pages/dashboards/Audit/analytics/ContinouosImprovement"));

//audit/Observations/SSPA
const ObservationMenu = async(() => import("../pages/dashboards/Audit/Observations"));
const ObservationForm = async(() => import("../pages/dashboards/Audit/Observations/Form"));
const ObservationList = async(() => import("../pages/dashboards/Audit/Observations/List"));
const ObservationDetail = async(() => import("../pages/dashboards/Audit/Observations/Detail"));
const ObservationPDF = async(() => import("../pages/dashboards/Audit/Observations/PDF"))

const FlightsLog = async(() => import("../pages/dashboards/FlightsLog/index"));
const FlightsForm = async(() => import("../pages/dashboards/FlightsLog/Form"));
const FlightsList = async(() => import("../pages/dashboards/FlightsLog/List"));
const FlightsAnal = async(() => import("../pages/dashboards/FlightsLog/Analytics"));
const FlightsMultimedia = async(() => import("../pages/dashboards/FlightsLog/Multimedia"));
const ZoneFiles = async(() => import("../pages/dashboards/FlightsLog/Multimedia/ZoneFiles"));
const Calendar = async(() => import("../pages/dashboards/Calendar"));
const Profile = async(() => import("../pages/dashboards/Profile/Profile"));
const FlightsCalendar = async(() => import("../pages/dashboards/FlightsCalendar"));
const FlightDetail = async(() => import("../pages/dashboards/FlightsLog/Detail"));

const Ticket = async(() => import("../pages/dashboards/Ticket"));

const CommentsMenu = async(() => import("../pages/dashboards/Comments"));
const CommentsForm = async(() => import("../pages/dashboards/Comments/form"));
const CommentsList = async(() => import("../pages/dashboards/Comments/list"));
const CommentsDetail = async(() => import("../pages/dashboards/Comments/detail"));

const DailyReportMenu = async(() => import("../pages/dashboards/DailyReport"));
const DailyReportForm = async(() => import("../pages/dashboards/DailyReport/Form"));
const DailyReportList = async(() => import("../pages/dashboards/DailyReport/List"));
const DailyReportDetail = async(() => import("../pages/dashboards/DailyReport/Detail"));
const ModifyActivities = async(() => import("../pages/dashboards/DailyReport/ModifyActivity"));
const DailyReportAnalytics = async(() => import("../pages/dashboards/DailyReport/Analytics"));

const SSPAMenu = async(() => import("../pages/dashboards/SSPA"));
const SSPAForm = async(() => import("../pages/dashboards/SSPA/Form"));
const SSPAList = async(() => import("../pages/dashboards/SSPA/List"));
const SSPADetail = async(() => import("../pages/dashboards/SSPA/Detail"));
const SSPAAnalytics = async(() => import("../pages/dashboards/SSPA/analytics"));
const SSPAAnalyticsAggregate = async(() => import("../pages/dashboards/SSPA/analytics/Aggregate"));

const DocsManagement = async(() => import("../pages/dashboards/DocsManagement"));

// // Forms components
// const Pickers = async(() => import("../pages/forms/Pickers"));
// const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
// const Selects = async(() => import("../pages/forms/Selects"));
// const TextFields = async(() => import("../pages/forms/TextFields"));
// const Dropzone = async(() => import("../pages/forms/Dropzone"));
// const Editors = async(() => import("../pages/forms/Editors"));
// const Formik = async(() => import("../pages/forms/Formik"));

// // Icons components
// const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
// const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// // Pages components
// const Blank = async(() => import("../pages/pages/Blank"));
// const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
// const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
// const Orders = async(() => import("../pages/pages/Orders"));
// const Pricing = async(() => import("../pages/pages/Pricing"));
// const Profile = async(() => import("../pages/pages/Profile"));
// const Settings = async(() => import("../pages/pages/Settings"));
// const Tasks = async(() => import("../pages/pages/Tasks"));
// const Projects = async(() => import("../pages/pages/Projects"));
// const Calendar = async(() => import("../pages/pages/Calendar"));
// const Chat = async(() => import("../pages/pages/Chat"));

// // Tables components
// const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
// const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));
// const DataGrid = async(() => import("../pages/tables/DataGrid"));

// // Chart components
// const Chartjs = async(() => import("../pages/charts/Chartjs"));

// // Maps components
// const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// // Documentation
// const Welcome = async(() => import("../pages/docs/Welcome"));
// const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
// const EnvironmentVariables = async(
//   () => import("../pages/docs/EnvironmentVariables")
// );
// const Deployment = async(() => import("../pages/docs/Deployment"));
// const Theming = async(() => import("../pages/docs/Theming"));
// const StateManagement = async(() => import("../pages/docs/StateManagement"));
// const APICalls = async(() => import("../pages/docs/APICalls"));
// const ESLintAndPrettier = async(
//   () => import("../pages/docs/ESLintAndPrettier")
// );
// const Support = async(() => import("../pages/docs/Support"));
// const Changelog = async(() => import("../pages/docs/Changelog"));

// // Landing
// const Landing = async(() => import("../pages/presentation/Landing"));

// // Protected routes
// const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));
const dashboardsRoutesCover = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/auditorias/detalle/pdf/:id",
      name: "Auditoria",
      component: AuditPdfDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones/pdf/:id",
      name: "Observaciones",
      component: ObservationPDF,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora",
      name: "Bitácora de vuelos",
      component: FlightsLog,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/formulario",
      name: "Crear bitácora",
      component: FlightsForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/formulario/:id",
      name: "Editar bitácora",
      component: FlightsForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/listado",
      name: "Listado de vuelos",
      component: FlightsList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/detalle/:id",
      name: "Detalle de bitácora",
      component: FlightDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/analitica",
      name: "Analitica de vuelos",
      component: FlightsAnal,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/multimedia",
      name: "Multimedia de vuelos",
      component: FlightsMultimedia,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-bitacora/multimedia/:id",
      name: "Multimedia por zona",
      component: ZoneFiles,
      guard: AuthGuard
    },
  ],
  component: Dashboard,
  guard: AuthGuard
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/auditorias/menu",
      name: "Auditoria",
      component: AuditMenu,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/formulario",
      name: "Auditoria",
      component: AuditForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/formulario/:id",
      name: "Auditoria",
      component: AuditForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/listado",
      name: "Auditoria",
      component: AuditList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/multimedia",
      name: "Auditoria",
      component: AuditMultimedia,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/detalle/:id",
      name: "Auditoria",
      component: AuditDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones",
      name: "Observaciones",
      component: ObservationMenu,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones/formulario",
      name: "Observaciones",
      component: ObservationForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones/formulario/:id",
      name: "Observaciones",
      component: ObservationForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones/listado",
      name: "Observaciones",
      component: ObservationList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/observaciones/detalle/:id",
      name: "Observaciones",
      component: ObservationDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/vuelos-calendario",
      name: "Calendario de vuelos",
      component: FlightsCalendar,
      guard: AuthGuard
    },
    {
      path: "/dashboard/calendario",
      name: "Calendario",
      component: Calendar,
      guard: AuthGuard
    },
    {
      path: "/dashboard/perfil",
      name: "Perfil",
      component: Profile,
      guard: AuthGuard
    },
    {
      path: "/dashboard/ticket",
      name: "Reporte de situación",
      component: Ticket,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica",
      name: "Analítica",
      component: Analytics,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica/categoria/:category",
      name: "Analítica",
      component: Category,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica/frentes",
      name: "Analítica",
      component: AuditsPerZone,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica/contratistas",
      name: "Analítica",
      component: AuditsPerContractor,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica/contratistas/:id",
      name: "Analítica",
      component: ContractorsAdvanced,
      guard: AuthGuard
    },
    {
      path: "/dashboard/auditorias/analitica/mejora-continua",
      name: "Analítica",
      component: ContinouosImprovement,
      guard: AuthGuard
    },
    {
      path: "/dashboard/comentarios/menu",
      name: "Comentarios",
      component: CommentsMenu,
      guard: AuthGuard
    },
    {
      path: "/dashboard/comentarios/formulario",
      name: "Comentarios",
      component: CommentsForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/comentarios/listado",
      name: "Comentarios",
      component: CommentsList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/comentarios/detalle/:id",
      name: "Comentario",
      component: CommentsDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario",
      name: "Menu Reporte Diario",
      component: DailyReportMenu,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/formulario",
      name: "Formulario Reporte Diario",
      component: DailyReportForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/formulario/:id",
      name: "Formulario Reporte Diario",
      component: DailyReportForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/listado",
      name: "Listado Reporte Diario",
      component: DailyReportList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/detalle/:id",
      name: "Detalle Reporte Diario",
      component: DailyReportDetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/modificar-actividades",
      name: "Modificar Actividades",
      component: ModifyActivities,
      guard: AuthGuard
    },
    {
      path: "/dashboard/reporte-diario/analitica",
      name: "Analítica Reporte Diario",
      component: DailyReportAnalytics,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa",
      name: "Menu SSPA",
      component: SSPAMenu,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/formulario",
      name: "Formulario SSPA",
      component: SSPAForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/formulario/:id",
      name: "Formulario SSPA",
      component: SSPAForm,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/listado",
      name: "Listado SSPA",
      component: SSPAList,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/detalle/:id",
      name: "Detalle SSPA",
      component: SSPADetail,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/analytics",
      name: "Analítica SSPA",
      component: SSPAAnalytics,
      guard: AuthGuard
    },
    {
      path: "/dashboard/sspa/analytics/acumulado",
      name: "Analítica SSPA",
      component: SSPAAnalyticsAggregate,
      guard: AuthGuard
    },
    // {
    //   path: "/dashboard/analytics",
    //   name: "Analytics",
    //   component: Analytics,
    // },
    // {
    //   path: "/dashboard/saas",
    //   name: "SaaS",
    //   component: SaaS,
    // },
    {
      path: "/dashboard/docs-management",
      name: "Analítica SSPA",
      component: DocsManagement,
      guard: AuthGuard
    },
  ],
  component: Dashboard,
  guard: AuthGuard
};

// const pagesRoutes = {
//   id: "Pages",
//   path: "/pages",
//   icon: <Layout />,
//   children: [
//     {
//       path: "/pages/profile",
//       name: "Profile",
//       component: Profile,
//     },
//     {
//       path: "/pages/settings",
//       name: "Settings",
//       component: Settings,
//     },
//     {
//       path: "/pages/pricing",
//       name: "Pricing",
//       component: Pricing,
//     },
//     {
//       path: "/pages/chat",
//       name: "Chat",
//       component: Chat,
//     },
//     {
//       path: "/pages/blank",
//       name: "Blank Page",
//       component: Blank,
//     },
//   ],
//   component: null,
// };

// const projectsRoutes = {
//   id: "Projects",
//   path: "/projects",
//   icon: <Briefcase />,
//   badge: "8",
//   component: Projects,
//   children: null,
// };

// const invoiceRoutes = {
//   id: "Invoices",
//   path: "/invoices",
//   icon: <CreditCard />,
//   children: [
//     {
//       path: "/invoices",
//       name: "List",
//       component: InvoiceList,
//     },
//     {
//       path: "/invoices/detail",
//       name: "Details",
//       component: InvoiceDetails,
//     },
//   ],
//   component: null,
// };

// const orderRoutes = {
//   id: "Orders",
//   path: "/orders",
//   icon: <ShoppingCart />,
//   component: Orders,
//   children: null,
// };

// const tasksRoutes = {
//   id: "Tasks",
//   path: "/tasks",
//   icon: <CheckSquare />,
//   badge: "17",
//   component: Tasks,
//   children: null,
// };

// const calendarRoutes = {
//   id: "Calendar",
//   path: "/calendar",
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null,
// };

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    // {
    //   path: "/auth/sign-up",
    //   name: "Sign Up",
    //   component: SignUp,
    // },
    // {
    //   path: "/auth/reset-password",
    //   name: "Reset Password",
    //   component: ResetPassword,
    // },
    // {
    //   path: "/auth/404",
    //   name: "404 Page",
    //   component: Page404,
    // },
    // {
    //   path: "/auth/500",
    //   name: "500 Page",
    //   component: Page500,
    // },
  ],
  component: null,
};

// const componentsRoutes = {
//   id: "Components",
//   path: "/components",
//   header: "Elements",
//   icon: <Grid />,
//   children: [
//     {
//       path: "/components/alerts",
//       name: "Alerts",
//       component: Alerts,
//     },
//     {
//       path: "/components/avatars",
//       name: "Avatars",
//       component: Avatars,
//     },
//     {
//       path: "/components/badges",
//       name: "Badges",
//       component: Badges,
//     },
//     {
//       path: "/components/buttons",
//       name: "Buttons",
//       component: Buttons,
//     },
//     {
//       path: "/components/cards",
//       name: "Cards",
//       component: Cards,
//     },
//     {
//       path: "/components/chips",
//       name: "Chips",
//       component: Chips,
//     },
//     {
//       path: "/components/dialogs",
//       name: "Dialogs",
//       component: Dialogs,
//     },
//     {
//       path: "/components/expansion-panels",
//       name: "Expansion Panels",
//       component: ExpPanels,
//     },
//     {
//       path: "/components/lists",
//       name: "Lists",
//       component: Lists,
//     },
//     {
//       path: "/components/menus",
//       name: "Menus",
//       component: Menus,
//     },
//     {
//       path: "/components/pagination",
//       name: "Pagination",
//       component: Pagination,
//     },
//     {
//       path: "/components/progress",
//       name: "Progress",
//       component: Progress,
//     },
//     {
//       path: "/components/snackbars",
//       name: "Snackbars",
//       component: Snackbars,
//     },
//     {
//       path: "/components/tooltips",
//       name: "Tooltips",
//       component: Tooltips,
//     },
//   ],
//   component: null,
// };

// const formsRoutes = {
//   id: "Forms",
//   path: "/forms",
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: "/forms/pickers",
//       name: "Pickers",
//       component: Pickers,
//     },
//     {
//       path: "/forms/selection-controls",
//       name: "Selection Controls",
//       component: SelectionCtrls,
//     },
//     {
//       path: "/forms/selects",
//       name: "Selects",
//       component: Selects,
//     },
//     {
//       path: "/forms/text-fields",
//       name: "Text Fields",
//       component: TextFields,
//     },
//     {
//       path: "/forms/dropzone",
//       name: "Dropzone",
//       component: Dropzone,
//     },
//     {
//       path: "/forms/editors",
//       name: "Editors",
//       component: Editors,
//     },
//     {
//       path: "/forms/formik",
//       name: "Formik",
//       component: Formik,
//     },
//   ],
//   component: null,
// };

// const tablesRoutes = {
//   id: "Tables",
//   path: "/tables",
//   icon: <List />,
//   children: [
//     {
//       path: "/tables/simple-table",
//       name: "Simple Table",
//       component: SimpleTable,
//     },
//     {
//       path: "/tables/advanced-table",
//       name: "Advanced Table",
//       component: AdvancedTable,
//     },
//     {
//       path: "/tables/data-grid",
//       name: "Data Grid",
//       component: DataGrid,
//     },
//   ],
//   component: null,
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons,
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons,
//     },
//   ],
//   component: null,
// };

// const chartRoutes = {
//   id: "Charts",
//   path: "/charts",
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null,
// };

// const mapsRoutes = {
//   id: "Maps",
//   path: "/maps",
//   icon: <Map />,
//   children: [
//     {
//       path: "/maps/google-maps",
//       name: "Google Maps",
//       component: GoogleMaps,
//     },
//     {
//       path: "/maps/vector-maps",
//       name: "Vector Maps",
//       component: VectorMaps,
//     },
//   ],
//   component: null,
// };

const landingRoutes = {
  id: "Landing Page",
  header: "Docs",
  icon: <Monitor />,
  children: null,
  path: "/",
  name: "Sign In",
  component: SignIn,
};

// const documentationRoutes = {
//   id: "Documentation",
//   path: "/documentation",
//   header: "Material App",
//   icon: <BookOpen />,
//   children: [
//     {
//       path: "/documentation/welcome",
//       name: "Welcome",
//       component: Welcome,
//     },
//     {
//       path: "/documentation/getting-started",
//       name: "Getting Started",
//       component: GettingStarted,
//     },
//     {
//       path: "/documentation/environment-variables",
//       name: "Environment Variables",
//       component: EnvironmentVariables,
//     },
//     {
//       path: "/documentation/deployment",
//       name: "Deployment",
//       component: Deployment,
//     },
//     {
//       path: "/documentation/theming",
//       name: "Theming",
//       component: Theming,
//     },
//     {
//       path: "/documentation/state-management",
//       name: "State Management",
//       component: StateManagement,
//     },
//     {
//       path: "/documentation/api-calls",
//       name: "API Calls",
//       component: APICalls,
//     },
//     {
//       path: "/documentation/eslint-and-prettier",
//       name: "ESLint & Prettier",
//       component: ESLintAndPrettier,
//     },
//     {
//       path: "/documentation/support",
//       name: "Support",
//       component: Support,
//     },
//   ],
//   component: null,
// };

// const changelogRoutes = {
//   id: "Changelog",
//   path: "/changelog",
//   badge: "v2.0.0",
//   icon: <List />,
//   component: Changelog,
//   children: null,
// };

// // This route is only visible while signed in
// const protectedPageRoutes = {
//   id: "Private",
//   path: "/private",
//   component: ProtectedPage,
//   children: null,
//   guard: AuthGuard,
// };

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  // pagesRoutes,
  // projectsRoutes,
  // orderRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];

export const dashboardLayoutCoverRoutes = [
  dashboardsRoutesCover,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// // Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// // Routes that are protected
// export const protectedRoutes = [protectedPageRoutes];

// // Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  //   pagesRoutes,
  //   projectsRoutes,
  //   orderRoutes,
  //   invoiceRoutes,
  //   tasksRoutes,
  //   calendarRoutes,
  authRoutes,
  //   componentsRoutes,
  //   chartRoutes,
  //   formsRoutes,
  //   tablesRoutes,
  //   iconsRoutes,
  //   mapsRoutes,
  //   documentationRoutes,
  //   changelogRoutes,
];
